import { useEffect, useState } from 'react';


const Home = () => {
   return (
      <div className="container py-3">
         <div className="alert alert-primary">
            Welcome to Flashionary!
         </div>
         <div className="alert alert-info">
            Use sidebar and explore application :-))))
         </div>
      </div>
   );
};

export default Home;
