


const Categories = () => {
   return (
      <>
         Categories
      </>
   )
}


export default Categories;