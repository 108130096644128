export const TTSTypes = ['Google', 'Microsoft'];

// export const SentenceTypes = ['Simple', 'Expression', 'Idiom'];
export const SentenceTypes = ['Simple', 'Expression', 'SemanticPoint', 'Other'];

export const GroupTypes = [
   'Synonym',
   'Antonym',
   'Dictation',
   'Pronunciation',
   'Confusing',
   'Other',
];

export const compoundTypes = ['Single', 'Closed', 'Hyphenated', 'Open'];

export const storyTypes = ['Simple', 'Music'];
export const enum storyTypesEnum {
   simple = 'Simple',
   music = 'Music',
}
